<template>
   <div>
    <b-modal
      id="commercialExpnsEditModal" 
      header-class="pb-2"
      size="xl"
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Статья коммерческих расходов
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2 px-1">
        <b-col class="col-6">
          <b-form-group
            label="Какие расходы предстоят?"
            labele-for="directCostTitle" 
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="titleState">
            <b-form-checkbox
              style="position: absolute; top: 0; right: 2rem;"
              v-model="titleSelector"
              name="choseToSelect"
              value="true">
              Выбрать из списка
            </b-form-checkbox>
            <b-form-input  v-if="!titleSelector"
              id="direcCostTitle"
              v-model="commercialExpnsData.title"
              :state="titleState">
            </b-form-input>
            <custom-dropdown 
              v-if="titleSelector"
              v-model="titleOptions"
              :defaultItem="commercialExpnsData.titleId"
              placeholder="Пожалуйстав, выберите наименование из списка">
            </custom-dropdown>
          </b-form-group>
        </b-col>
        <b-col class="col-6 mt-1">
            <h6 class="mb-1">Коммерческие расходы</h6>
            <p class="fs--1">
             Это затраты, связанные с продажей продукции, товаров, работ, услуг. 
             Торговые организации могут включать в состав коммерческих расходов не только затраты, 
             связанные с продажей, но затраты, связанные с приобретением товаров (транспортно-заготовительные расходы, ТЗР).
            </p>
        </b-col>
      </b-row>

      <b-row class="mt-2">  
        <b-col sm="12">
          <wizard-section 
            :data="commercialExpnsData ? commercialExpnsData.values : [0]" 
            unitsTitle="рублей"
            titleHeight="4rem"
            :defaultMax="100000"
            :decimals="0">
            <slot style="height: 1rem;">
              <p class="font=proxima fs--1 mt-2">
                Укажите инфорацию о величине коммерческих расходов в динамике.  
              </p>
            </slot>
          </wizard-section>
        </b-col>
      </b-row>


      <template v-slot:modal-footer>

        <div class="w-100">
          <div class="btn btn-outline-dark delete-btn ml-2 px-2 py-0 float-left"
            @click="deleteItem">
            <i class="far fa-trash-alt mr-2 py-0"></i>
            <span class="font-proxima font-weight-light">
              Удалить
            </span>
          </div>
          
          <b-button 
            v-if="loadData"
            class="float-right"
            variant="outline-success"
            size="sm"
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button
            v-if="!loadData"
            variant="outline-success"
            size="sm"
            class="float-right"
            @click="saveData">
            Готово!
          </b-button>
        </div>

      </template>
      
    </b-modal>


  </div>
</template>

<script>
import CustomDropdown from '../widgets/CustomDropdown'
import WizardSection from '../widgets/Modals/IncomeCreate/WizardSection'

import CFCategories from '../../../store/CFCategories'

export default {
  data: () => ({
    showModal: false,
    loadData: false,
    title: '',
    titleSelector: false,
    selectedTitle: null,
    expnsType: 'general',
    selectedCFCategory: null
  }),
  components: {
    CustomDropdown,
    WizardSection,
  },

  computed: {
    commercialExpnsData() {
      var data = this.$store.getters.getCommercialExpnsToEdit
      if (data) {
        if (data.titleType == 'system') {
          this.titleSelector = true
        }
        return data
      } else {
        return {title: '', titleId: null}
      }
    },

    titleOptions: {
      get: function() {
        return [
          { id: 0, title: 'Материальные затраты', cfId: 'co0' },
          { id: 1, title: 'Расходы на ТЭР и коммунальные услуги', cfId: 'co1' },
          { id: 2, title: 'Коммерческий персонал: ФОТ (фонд оплаты труда)', cfId: 'lb0' },
          { id: 3, title: 'Коммерческий персонал: начисления на ФОТ', cfId: 'lb1' },
          { id: 4, title: 'Амортизация коммерческих ОС и НМА', cfId: null },
          { id: 5, title: 'Арендная плата за коммерческие активы', cfId: 'co2' },
          { id: 6, title: 'Серийное сопровождение, авторский надзор', cfId: 'cm4' },
          { id: 7, title: 'Комиссионное вознаграждение посреднику', cfId: 'cm4' },
          { id: 8, title: 'Патентные и иные лицензионные платежи', cfId: 'cm4' },
          { id: 9, title: 'Расходы на маркетинг', cfId: 'cm1' },
          { id: 10, title: 'Расходы на рекламу', cfId: 'cm2' },
          { id: 11, title: 'Расходы на спонсорство', cfId: 'cm4' },
          { id: 12, title: 'Расходы на проведение выставок', cfId: 'cm3' },
          { id: 13, title: 'Транспортные услуги', cfId: 'co11'},
          { id: 14, title: 'Складские услуги', cfId: 'cm4' },
          { id: 15, title: 'Повышение квалификации ком.персонала', cfId: 'cm4' },
          { id: 16, title: 'Прочие работы и услуги сторонних организаций', cfId: 'cm4' },
          { id: 17, title: 'Расходы на лизинг ком. активов', cfId: 'fo1' },
          { id: 18, title: 'Ремонт и обслуживание ком. активов', cfId: 'co3' },
          { id: 19, title: 'Командировочные расходы', cfId: 'cm4' },
          { id: 20, title: 'Представительские расходы', cfId: 'cm4' },
          { id: 21, title: 'Прочие затраты коммерческого характера',  cfId: 'cm4' }
        ]
      },
      set: function(value) {
        if (value) {
          this.selectedTitle = value
        } else {
          this.selectedTitle = null
        }

      }
    },

    CFCategories: {
      get: function() {
        var allCategories = []

        allCategories = allCategories.concat(CFCategories.direct)
        allCategories = allCategories.concat(CFCategories.labor)
        allCategories = allCategories.concat(CFCategories.commercial)
        allCategories = allCategories.concat(CFCategories.common)

        return allCategories
      },
      set: function(value) {
         if (value) {
          this.selectedCFCategory = value
        } else {
          this.selectedCFCategory = null
        }
      }
    },

    titleState() {
      
      if (!this.titleSelector) {
        return this.commercialExpnsData.title.length >= 4 ? true : false
      } 

      if (this.titleSelector && this.selectedTitle) {
        return true
      } else {
        return false
      }
      
    },

    invalidFeedback() {

      if (this.titleSelector) {
        if (this.selectedTitle) {
          return ''
        } else {
          return 'Пожалуйства, выберите наименование из списка...'
        }
      } else {
        if (this.commercialExpnsData.title.length >= 4) {
          return ''
        } else if (this.commercialExpnsData.title.length > 0) {
          return 'Название должно быть не менее 4-х символов'
        } else {
          return 'Пожалуйства, введине название...'
        }
      } 
    },

    validFeedback() {
      return this.state === true ? 'Отлично!' : ''
    },
  },

  methods: {
    async saveData() {
      this.loadData = true

      if (!this.titleState) {
        return
      }

      var payload = this.$store.getters.getCommercialExpnsToEdit
      
      payload.type = this.expnsType
      if (this.titleSelector) {
        payload.title = this.selectedTitle.title
        payload.titleId = parseInt(this.selectedTitle.id)
        payload.titleType = 'system'
        payload.cfData = this.CFCategories.find(item => item.id == this.selectedTitle.cfId)
      } else {
         payload.titleId = null
      }
      payload.titleType = this.titleSelector ? 'system' : 'custom'

      try {
        if (payload.id) {
          await this.$store.dispatch('updateCommercialExpnsDocument', payload)
        } else {
          await this.$store.dispatch('createCommercialExpnsDocument', payload)
        }
      } catch (error) {
        window.console.log('Unable to create commercial expense document', error)
      }
      
      this.loadData = false
      this.closeModal()
    },

    closeModal() {
      this.showModal = false
    },

    deleteItem() {
      const payload = this.$store.getters.getCommercialExpnsToEdit

      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить данный расход?', {
        title: 'Подтвердите удаление',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(async (agree) => {
        if (agree) {
          await this.$store.dispatch('deleteCommercialExpnsDocument', payload)
          this.closeModal()
        } 
      })
      .catch(err => {
          // An error occurred
          window.console.log('unable to delete commercial expense document: ', err)
      })
    }

  }

}
</script>

<style lang="scss" scoped>

.income-title {
  line-height: 90%; 
  margin-top: 2px; 
  margin-bottom: 0.5rem;
}

.income-option {
  padding: 0 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(14, 187, 245, 0.10);
  }
  &.selected {
    background: rgba(14, 187, 245, 0.10);
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}

</style>